import React, { useState, useEffect } from "react";
import { inject, observer } from "mobx-react";
import moment from "moment";
import { Provider } from "mobx-react";
import PropTypes from "prop-types";
import { BrowserRouter, Switch, Route, Link } from "react-router-dom";
import { isE2ETest, showCookieBanner } from "../../sma-shared/Utils/utils";
import { sstTrackingHelper } from "../../Utils/utils";
import Header from "../../sma-shared/Components/Header";
import Footer from "../../sma-shared/Components/Footer";
import "react-notifications/lib/notifications.css";
import "./_style.scss";
import ModalPopup from "../../Components/ModalPopup";
import VideoPlayer from "../../Components/VideoPlayer";
import InfoBox from "../../Components/InfoBox";
import HelpWindow from "../../Components/HelpWindow";
import ADVBanner from "../../sma-shared/Components/ADVBanner";
import OverviewPage from "../OverviewPage";
import NotFoundPage from "../NotFoundPage";
import CreateGiftPages from "../CreateGiftPages";
import HelpText from "../../Components/HelpText";
import RestrictedRoute from "./restrictedRoute";
import Registration from "../Authentication/SignUp";
import Login from "../Authentication/Login";
import LoadingPage from "../LoadingPage";
import ResetPassword from "../Authentication/ResetPassword";
import CustomNotificationContainer from "../../Components/CustomNotificationContainer";
import DataProtection from "../DataProtection";
import Imprint from "../Imprint";
import { getRoleBasedProductVideos } from "../../Utils/utils";
import AdminData from "../../Components/AdminData";

import {
  OVERVIEW_PATH,
  SETTINGS_PATH,
  GIFTCASE_PATH,
  EMPLOYEE_PATH,
  REGISTRATION,
  RESET_PASSWORD,
  DATA_PROTECTION_PATH,
  IMPRINT_PATH,
} from "../../Utils/paths";
import {
  OVERVIEW_HEADER_NAME,
  SETTINGS_HEADER_NAME,
  EMPLOYEE_HEADER_NAME,
  APPLICATIONTITLE,
  HR_USER,
  DELEGATE_USER,
  AVV_DOCUMENT_NAME,
  ROLES,
  NO_LICENCE_PI,
  siteid,
  event,
} from "../../Utils/constants";

// import 'react-notifications/lib/notifications.css';
// import './_style.scss';
import EmployeesOverviewPage from "../EmployeesOverviewPage";
import EmployeePage from "../EmployeePage";

import config from "../../config";
import SettingsPage from "../SettingsPage";

function App(props) {
  const rootStore = props.rootStore;
  const { apiStore, modalStore, userSettingsStore } = props.rootStore;
  const [headerLinks, setHeaderLinks] = useState([]);
  const [appAdminUsers, setAppAdminUsers] = useState(apiStore.adminUsers);

  useEffect(() => {
    const links = createHeaderLinks();
    setHeaderLinks(links);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiStore.roles]);

  useEffect(() => {
    setAppAdminUsers(apiStore.adminUsers);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiStore.adminUsers]);

  useEffect(() => {
    showAskingRolePermisionInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userSettingsStore.hideAskingRolePermision]);

  if (window.location.search.indexOf("?e2e") >= 0) {
    sessionStorage.setItem("e2e", true);
  }
  const acceptBannerText = (
    <div>
      Für die Nutzung des {config.adv.projectName}s ist der Abschluss eines
      Vertrages zur Auftragsverarbeitung gem. Art 28 DSGVO zwingend
      erforderlich. Bitte stimmen Sie der jeweils aktuellsten Version des
      Vertrags hier zu. Einen von uns unterzeichneten Vertrag können Sie{" "}
      <a
        href={"/" + config.adv.pdfFileName}
        target="_blank"
        rel="noopener noreferrer"
      >
        an dieser Stelle
      </a>{" "}
      vorab prüfen und dem Vertrag anschließend elektronisch zustimmen.
    </div>
  );

  /* Header Creation  */
  const createUserProfile = () => {
    const userInfo =
      rootStore && apiStore ? rootStore.apiStore.userInfo : undefined;

    const userProfile = {
      firstName: userInfo ? userInfo.FirstName : "",
      lastName: userInfo ? userInfo.LastName : "",
      guestUser: !userInfo || userInfo.PI === NO_LICENCE_PI ? true : false,
      roles: userInfo ? userInfo.roles : [],
    };
    return userProfile;
  };

  const showAskingRolePermisionInfo = () => {
    const firstAdmin =
      apiStore.adminUsers.length > 0 ? apiStore.adminUsers[0] : undefined;
    if (
      !userSettingsStore.hideAskingRolePermision &&
      !apiStore.roles.includes(ROLES.admin) &&
      firstAdmin
    ) {
      modalStore.showConfirm(
        "",
        `Sind Sie ein Mitarbeiter aus der Finanzbuchhaltung oder der Entgeltabrechnung, muss Ihnen der Administrator (${firstAdmin.email})
      weitergehende Rechte einräumen und Ihnen die Rolle „Mitarbeiter der Fibu“ und/oder „Mitarbeiter der Entgeltabrechnung“ geben.`,
        "",
        () => {
          userSettingsStore.hideAskingRolePermision = true;
          userSettingsStore.saveSettings();
        }
      );
    }
  };

  function handleADVClick(props) {
    sstTrackingHelper("ADV", "ADV: anzeigen", "/app/showADV");

    if (apiStore.userInfo.disconnected === true) {
      const pdfWindow = window.open();
      pdfWindow.location.href = `/${AVV_DOCUMENT_NAME}`;
    } else {
      const title = "Auftragsverarbeitung";
      const { infoBoxStore, apiStore } = props.rootStore;
      const linkPDF = `/${props.acceptedDocument || AVV_DOCUMENT_NAME}`;
      const acceptButton = "Akzeptieren";
      const acceptDate =
        apiStore.advAcceptedDate !== undefined
          ? formatUnixDate(props.rootStore.apiStore.advAcceptedDate)
          : "";

      /* info box text blocks */
      const acceptedContent = (
        <div>
          Sie haben die Auftragsverarbeitung bereits am {acceptDate} bestätigt.
          Sie können den Vertrag{" "}
          <a target="_blank" rel="noopener noreferrer" href={linkPDF}>
            hier
          </a>{" "}
          erneut herunterladen.
        </div>
      );

      const notAcceptedContent = (
        <div>
          Sie haben die Auftragsverarbeitung noch nicht bestätigt. Bitte
          bestätigen Sie diese, um die Applikation weiterhin nutzen zu können.
          Sie können den Vertrag{" "}
          <a target="_blank" rel="noopener noreferrer" href={linkPDF}>
            hier
          </a>{" "}
          herunterladen.
        </div>
      );
      /* info box content used for ADV */
      !apiStore.advAccepted
        ? infoBoxStore.showConfirm(
            title,
            notAcceptedContent,
            acceptButton,
            handleAcceptClick(apiStore)
          )
        : infoBoxStore.showNoButtons(title, acceptedContent);
    }
  }

  function handleAcceptClick(props) {
    if (!props.rootstore.apiStore.advAccepted) {
      props.rootStore.apiStore.acceptADV(AVV_DOCUMENT_NAME);
      sstTrackingHelper("ADV", "ADV: akzeptieren", "/app/acceptADV");
    }
  }

  function handleLegalClick(props) {
    sstTrackingHelper(
      "Rechtlicher Hinweis",
      "Rechtlicher Hinweis: anzeigen",
      "/app/showLegal"
    );
    const title = "Rechtlicher Hinweis";
    const content = (
      <div>
        Hinweis zum Gesetz über außergerichtliche Rechtsdienstleistungen oder
        Steuerberatungen:
        <br />
        Die selbständige Erbringung außergerichtlicher Rechtsdienstleistungen
        oder von Steuerberatungen ist nur in dem Umfang zulässig, in dem sie
        durch das Rechtsdienstleistungsgesetz (RDG), das Steuerberatungsgesetz
        (StBerG) sowie der Bundesrechtsanwaltsordnung (BRAO) oder aufgrund
        anderer Gesetze erlaubt wird. Die hier erbrachte Leistung stellt kein
        automatisches Rechtsberatungssystem und somit keine
        Rechtsdienstleistungen i.S.v. Rechtsberatung oder Steuerberatung dar.
        Das dargestellte Ergebnis ist keine rechtsverbindliche Bewertung und
        bedarf der Überprüfung eines Rechtsanwalts oder eines Beraters bzw.
        Vertreters in Rechtsangelegenheiten i.S.v. § 3 Abs. 1 der
        Bundesrechtsanwaltsordnung (BRAO) oder eines Steuerberaters.
      </div>
    );
    props.rootStore.infoBoxStore.showNoButtons(title, content);
  }

  /* Footer Creation */
  const createApplicationInformation = () => {
    const info = {
      deployDate: apiStore.userInfo.DeployDate,
      backendBuildVersion: apiStore.userInfo.BackendBuildVersion,
    };
    return info;
  };

  const createFooterLinks = () => {
    const links = [
      <InfoBox />,
      <button onClick={() => handleADVClick(props)}>
        Auftragsverarbeitung
      </button>,
      <Link to={DATA_PROTECTION_PATH}>Datenschutzerklärung</Link>,
      <button href="#" onClick={() => showCookieBanner()}>
        Cookie-Einstellungen
      </button>,
      <button onClick={() => handleLegalClick(props)}>
        Rechtlicher Hinweis
      </button>,
      <Link to={IMPRINT_PATH}>Impressum</Link>,
    ];
    return links;
  };

  function formatUnixDate(unixTimestamp) {
    const timestamp = unixTimestamp.toString().substring(0, 10);
    let date = moment.unix(timestamp);
    date = date.format("DD.MM.YYYY HH:mm");

    const splitDate = date.split(" ");
    const formatDate = `${splitDate[0]} um ${splitDate[1]} Uhr`;

    return formatDate;
  }

  const createHeaderLinks = () => {
    const { apiStore } = props.rootStore;
    if (!apiStore.roles.includes(ROLES.admin)) {
      return [];
    }

    const links = [
      {
        title: OVERVIEW_HEADER_NAME,
        path: OVERVIEW_PATH,
      },
      {
        title: EMPLOYEE_HEADER_NAME,
        path: EMPLOYEE_PATH,
      },
      {
        title: SETTINGS_HEADER_NAME,
        path: SETTINGS_PATH,
        className: apiStore.settings === "" ? "alert-circle" : "",
      },
    ];
    return links;
  };

  const getApplicationContent = () => {
    if (apiStore.userInfo.disconnected === true) {
      return (
        <Switch>
          <Route exact path={REGISTRATION} component={Registration} />
          <Route exact path={RESET_PASSWORD} component={ResetPassword} />
          <Route
            path={`${DATA_PROTECTION_PATH}`}
            component={() => <DataProtection />}
          />
          <Route path={`${IMPRINT_PATH}`} component={() => <Imprint />} />
          <Route component={Login} />
        </Switch>
      );
    } else if (apiStore.userInfo.disconnected === "pending") {
      return (
        <Switch>
          <Route component={LoadingPage} />
        </Switch>
      );
    } else {
      return (
        <Switch>
          <Route exact path={OVERVIEW_PATH()} component={OverviewPage} />
          <RestrictedRoute
            exact
            path={SETTINGS_PATH}
            component={SettingsPage}
            userInfo={apiStore.userInfo}
            allowRoles={[ROLES.admin]}
          />
          <RestrictedRoute
            exact
            path={EMPLOYEE_PATH}
            component={EmployeesOverviewPage}
            userInfo={apiStore.userInfo}
            allowRoles={[ROLES.admin]}
          />
          <RestrictedRoute
            exact
            path={`${EMPLOYEE_PATH}/:employeeId`}
            component={EmployeePage}
            userInfo={apiStore.userInfo}
            allowRoles={[ROLES.admin]}
          />
          <Route
            exact
            path={`${GIFTCASE_PATH}/:page/:giftId`}
            component={CreateGiftPages}
          />
          <Route
            exact
            path={`${GIFTCASE_PATH}/:page`}
            component={CreateGiftPages}
          />
          <Route exact path={GIFTCASE_PATH} component={CreateGiftPages} />
          <Route
            path={`${DATA_PROTECTION_PATH}`}
            component={() => <DataProtection />}
          />
          <Route path={`${IMPRINT_PATH}`} component={() => <Imprint />} />
          <Route component={NotFoundPage} />
        </Switch>
      );
    }
  };

  const getUserMenuActions = () =>
    getRoleBasedProductVideos(apiStore.userInfo.roles).map((productVideo) => ({
      title: productVideo.shortTitle,
      onClick: () => {
        sstTrackingHelper(
          "Fallübersicht",
          "Open short informative videos",
          "/informative-videos"
        );
        modalStore.showConfirm(
          productVideo.title,
          <VideoPlayer source={productVideo.source} />,
          "Schließen"
        );
      },
    }));

  const getCustomHeaderComponents = () => {
    const headerCustomComponents =
      appAdminUsers.length > 0 && !apiStore.roles.includes(ROLES.admin) ? (
        <AdminData adminUsers={appAdminUsers} />
      ) : (
        ""
      );
    return headerCustomComponents;
  };

  return (
    <BrowserRouter>
      <Provider>
        <div className={`App${isE2ETest ? " E2E" : ""}`}>
          <Header
            applicationTitle={APPLICATIONTITLE}
            userProfile={createUserProfile()}
            userInfo={rootStore && apiStore ? apiStore.userInfo : undefined}
            userTitle={apiStore.userInfo.AccessToken ? DELEGATE_USER : HR_USER}
            headerLinks={headerLinks}
            userMenuActions={getUserMenuActions()}
            customComponents={getCustomHeaderComponents()}
            siteid={siteid}
            event={event}
          />
          <div className="application-content">{getApplicationContent()}</div>
          <HelpText />
          <Footer
            footerLinks={createFooterLinks()}
            appInfo={createApplicationInformation()}
          />
          <CustomNotificationContainer props={props} />
          <ModalPopup />
          <InfoBox />
          <HelpWindow />
          <ADVBanner
            avvPdfFileName={config.adv.pdfFileName}
            isADVAccepted={
              apiStore.advAccepted === undefined ? true : apiStore.advAccepted
            }
            advPageId={config.adv.trackPIPageId}
            isADVForced={apiStore.userInfo.advForce}
            acceptADV={() => apiStore.acceptADV(config.adv.pdfFileName)}
            projectName={config.adv.projectName}
            customAcceptBannerText={acceptBannerText}
            siteid={siteid}
            event={event}
          />
        </div>
      </Provider>
    </BrowserRouter>
  );
}

App.propTypes = {
  rootStore: PropTypes.object.isRequired,
};

export default inject("rootStore")(observer(App));
