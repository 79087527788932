import { observable, action, observe, decorate } from "mobx";
import axios from "axios";
import { isLocalhost, showNotification } from "../sma-shared/Utils/utils";
import { sstTrackingHelper } from "../Utils/utils";
import { saveAs } from "file-saver";
import { EMAIL_SEND_SUCCESS, ROLES, UPDATE_STATUS } from "../Utils/constants";
import { detectIE } from "../Utils/checkBrowser";

require("string.prototype.startswith");

const DOMAIN_ERROR = "Diese E-Mail-Domain wird bereits verwendet.";
const REFRESH_DATA_INTERVAL = 5 * 60 * 1000; // 5 minutes

let csrfToken;
let apiBaseURL =
  isLocalhost && !process.env.REACT_APP_BACKEND_URL
    ? "https://gm.smartapplications-dev.haufe.de"
    : "";

function getAxiosOptions() {
  return csrfToken
    ? { headers: { "X-CSRF-Token": csrfToken, Pragma: "no-cache" } }
    : { headers: { Pragma: "no-cache" } };
}

export default class APIStore {
  rootStore;
  apiBaseURL = apiBaseURL;

  pingErrorOccured = false;
  apiErrorOccured = false;
  apiErrorDismissed = false;
  lastApiError = {};
  lastApiErrorMessage = "";
  dataProtection = "";
  imprint = "";

  userInfo = observable({ disconnected: true });
  settings = undefined;
  gifts = observable([]);
  roles = observable([]);
  employees = observable([]);
  adminUsers = observable([]);
  advAccepted = undefined;
  advAcceptedDate;
  advDocument;

  constructor(rootStore) {
    this.rootStore = rootStore;
    this.getDataProtection();
    this.getUserInfo();
    this.getImprint();

    const disposerInfo = observe(this, "userInfo", (userInfo) => {
      if (userInfo.newValue.PI) {
        disposerInfo();

        if (!userInfo.newValue.AccessToken) {
          this.getUserSettings();
        }
        this.getSettings();
        this.getGifts();

        if (userInfo.newValue.roles.includes(ROLES.admin)) {
          this.getEmployees();
        }
        if (!userInfo.newValue.roles.includes(ROLES.admin)) {
          this.getAdminUsers();
        }

        setInterval(() => {
          this.getSettings();
          this.getGifts();

          if (userInfo.newValue.roles.includes(ROLES.admin)) {
            this.getEmployees();
          }
        }, REFRESH_DATA_INTERVAL);

        setInterval(() => {
          this.pingBackend();
        }, 60000);
      }
    });
  }

  pingBackend() {
    axios
      .get(`${apiBaseURL}/ping`, getAxiosOptions())
      .then((res) => {
        this.pingErrorOccured = false;
      })
      .catch((err) => {
        this.pingErrorOccured = true;
        console.log(err);

        if (err.response && err.response.status === 403) {
          // Forbidden - session expired or invalid
          if (!window.location.host.startsWith("localhost")) {
            setTimeout(() => {
              // Session is expired or user logged out in other session
              sstTrackingHelper(
                "timeout",
                "Session ungültig",
                "/session/expired"
              );
              window.location = "/auth/login";
            }, 100);
          }
        }
      });
  }

  async getUserInfo() {
    this.userInfo.disconnected = "pending";
    const isCleanCSRFId = await this.isCleanCSRFId();
    if (isCleanCSRFId) {
      axios
        .get(`${apiBaseURL}/api/userinfo`, getAxiosOptions())
        .then((res) => {
          this.lastApiError = false;
          csrfToken = res.data.smaid;
          delete res.data.smaid;
          this.userInfo = res.data;
          this.advAccepted = res.data.advAccepted || false;
          this.advAcceptedDate = res.data.advAcceptedDate;
          this.advDocument = res.data.advDocument;
          this.roles = res.data.roles ? res.data.roles : [];
        })
        .catch((err) => {
          this.userInfo = {};
          this.handleApiError(err);
        });
    } else {
      this.userInfo.disconnected = true;
    }
  }

  verifyEmail(userEmail, callback) {
    axios
      .get(
        `${apiBaseURL}/api/userinfo/check-login?email=${userEmail}`,
        getAxiosOptions()
      )
      .then((res) => {
        this.apiErrorOccured = false;
        callback(res.data);
      })
      .catch((err) => {
        this.handleApiError(err);
        if (callback) {
          callback(err.response.data);
        }
      });
  }

  acceptADV(document) {
    axios
      .put(
        `${apiBaseURL}/api/userinfo/acceptadv`,
        { advDocument: document },
        getAxiosOptions()
      )
      .then((res) => {
        this.apiErrorOccured = false;
        this.userInfo = Object.assign(this.userInfo, {
          advAccepted: true,
          advAcceptedDate: new Date().getTime(),
          advDocument: document,
        });
        this.advAccepted = true;
        this.advAcceptedDate = new Date().getTime();
        this.advDocument = document;
      });
  }

  getSettings() {
    axios
      .get(`${apiBaseURL}/api/settings`, getAxiosOptions())
      .then((res) => {
        this.lastApiError = false;
        this.settings = res.data;
      })
      .catch((err) => {
        this.settings = undefined;
        this.handleApiError(err);
      });
  }

  addOrUpdateSettings(settings, id, overwrite, successCallback) {
    if (id && id !== 0) {
      const data = { ...settings, overwrite };
      axios
        .put(`${apiBaseURL}/api/settings/${id}`, data, getAxiosOptions())
        .then((res) => {
          if (res.data.message === UPDATE_STATUS.notAllow) {
            this.rootStore.modalStore.showConfirm("Warnung", DOMAIN_ERROR);
          } else {
            if (res.data.message === UPDATE_STATUS.success) {
              showNotification("Einstellungen wurden gespeichert");
              this.apiErrorOccured = false;
              this.settings = res.data.settings;
            }
            if (successCallback) {
              successCallback(res.data);
            }
          }
          sstTrackingHelper(
            "settings.update",
            "Einstellungen gespeichert",
            "/settings/updated"
          );
        })
        .catch((err) => {
          this.handleApiError(err);
        });
    } else {
      axios
        .post(`${apiBaseURL}/api/settings`, settings, getAxiosOptions())
        .then((res) => {
          if (res.data.message === UPDATE_STATUS.notAllow) {
            this.rootStore.modalStore.showConfirm("Warnung", DOMAIN_ERROR);
          } else {
            if (res.data.message === UPDATE_STATUS.success) {
              showNotification("Einstellungen wurden gespeichert");
              this.apiErrorOccured = false;
              this.settings = res.data.settings;
            }
            if (successCallback) {
              successCallback(res.data);
            }
          }

          sstTrackingHelper(
            "settings.add",
            "Einstellungen gespeichert",
            "/settings/added"
          );
        })
        .catch((err) => {
          this.handleApiError(err);
        });
    }
  }

  getGifts() {
    axios
      .get(`${apiBaseURL}/api/gift`, getAxiosOptions())
      .then((res) => {
        this.lastApiError = false;
        this.gifts = res.data;
      })
      .catch((err) => {
        this.company = undefined;
        this.handleApiError(err);
      });
  }

  async refreshGift(id) {
    try {
      const res = await axios.get(
        `${apiBaseURL}/api/gift/${id}`,
        getAxiosOptions()
      );

      this.lastApiError = false;
      const index = this.gifts.findIndex((pos) => pos._id === id);
      if (index >= 0) {
        this.gifts[index] = res.data;
      }
    } catch (err) {
      this.handleApiError(err);
    }
  }

  async getGiftValueGuidance(receiverType) {
    try {
      const res = await axios.get(
        `${apiBaseURL}/api/gift/gift-value-guidance/${receiverType}`,
        getAxiosOptions()
      );
      this.lastApiError = false;
      return res.data;
    } catch (err) {
      this.handleApiError(err);
    }
  }

  async sendReminderForApprovalRequests(giftId) {
    try {
      const data = { reminder: "reminder" }; // Internet Explorer FIX / IE  cannot handle empty objects / NOT USED ON BACKEND
      const response = await axios.put(
        `${apiBaseURL}/api/gift/${giftId}/approval-request-reminder`,
        data,
        getAxiosOptions()
      );

      if (
        response.data.message === UPDATE_STATUS.success &&
        response.data.isEmailSent
      ) {
        showNotification(EMAIL_SEND_SUCCESS);
      }
      this.apiErrorOccured = false;
    } catch (error) {
      this.handleApiError(error);
    }
  }

  //createGift
  async addOrUpdateGift(gift, id, overwrite, { approvalStep, action }) {
    const createGiftStore = this.rootStore.createGiftStore;
    const { apiStep } = createGiftStore.currentWorkflowStep;

    if (id && id !== 0) {
      const data = {
        ...gift,
        overwrite,
        refreshedAt: gift.status.refreshedAt,
        action,
      };
      try {
        let res;

        if (apiStep === "entry-check")
          res = await axios.put(
            `${apiBaseURL}/api/gift/${id}`,
            data,
            getAxiosOptions()
          );
        else if (apiStep === "update-receiver")
          res = await axios.put(
            `${apiBaseURL}/api/gift/${id}/receiver`,
            data,
            getAxiosOptions()
          );
        else if (apiStep === "update-gift-data")
          res = await axios.put(
            `${apiBaseURL}/api/gift/${id}/gift-data`,
            data,
            getAxiosOptions()
          );
        else if (apiStep === "update-financial-accounting-data") {
          if (approvalStep === "financial-accounting-approval") {
            res = await axios.put(
              `${apiBaseURL}/api/gift/${id}/financial-accounting-approval`,
              data,
              getAxiosOptions()
            );
          } else {
            res = await axios.put(
              `${apiBaseURL}/api/gift/${id}/financial-accounting-data`,
              data,
              getAxiosOptions()
            );
          }
        } else if (
          apiStep === "complete-gift-case" &&
          approvalStep === "complete"
        ) {
          res = await axios.put(
            `${apiBaseURL}/api/gift/${id}/complete`,
            data,
            getAxiosOptions()
          );
        } else if (approvalStep === "approval-request") {
          res = await axios.put(
            `${apiBaseURL}/api/gift/${id}/approval-request`,
            data,
            getAxiosOptions()
          );
        } else if (approvalStep === "approval") {
          res = await axios.put(
            `${apiBaseURL}/api/gift/${id}/approval`,
            data,
            getAxiosOptions()
          );
        }

        if (res && res.data) {
          if (res && res.data && res.data.message === UPDATE_STATUS.success) {
            showNotification("Vorgang wurde gespeichert");
          }
          if (res.data.isEmailSent) {
            showNotification(EMAIL_SEND_SUCCESS);
          }

          this.apiErrorOccured = false;
          if (res.data.message === UPDATE_STATUS.removed) {
            this.gifts = this.gifts.filter(
              (gift) => res.data.gift._id !== gift._id
            );
          } else {
            this.gifts = this.gifts.map(function (gift) {
              return gift._id === id ? res.data.gift : gift;
            });
          }
          return res.data;
        }
      } catch (error) {
        this.handleApiError(error);
      }
    } else {
      try {
        const res = await axios.post(
          `${apiBaseURL}/api/gift`,
          gift,
          getAxiosOptions()
        );
        if (res.data.message === UPDATE_STATUS.success) {
          showNotification("Vorgang wurde erstellt");
        }

        this.apiErrorOccured = false;
        const data = [...this.gifts];
        data.push(res.data.gift);
        this.gifts = data;
        return res.data;
      } catch (error) {
        this.handleApiError(error);
      }
    }
  }

  async updateBatchGifts(
    financialApprovalGiftList = [],
    giftCompeleteGiftList = []
  ) {
    const numberOfBatchGifts =
      financialApprovalGiftList.length + giftCompeleteGiftList.length;

    try {
      if (numberOfBatchGifts > 0) {
        let numberOfUpdatedGiftsForFinancialApproval = 0;
        let numberOfUpdatedGiftsForCompletedGift = 0;

        if (financialApprovalGiftList.length > 0) {
          const apiResponse = await axios.put(
            `${apiBaseURL}/api/gift/financial-accounting-approval-bulk`,
            financialApprovalGiftList,
            getAxiosOptions()
          );
          numberOfUpdatedGiftsForFinancialApproval = apiResponse.data.filter(
            (response) => response.message === UPDATE_STATUS.success
          ).length;
        }
        if (giftCompeleteGiftList.length > 0) {
          const apiResponse = await axios.put(
            `${apiBaseURL}/api/gift/complete-bulk`,
            giftCompeleteGiftList,
            getAxiosOptions()
          );
          numberOfUpdatedGiftsForCompletedGift = apiResponse.data.filter(
            (response) => response.message === UPDATE_STATUS.success
          ).length;
        }

        this.apiErrorOccured = false;

        await this.getGifts();

        if (numberOfUpdatedGiftsForFinancialApproval > 0) {
          showNotification(
            `Stapelbearbeitung abgeschlossen (${numberOfUpdatedGiftsForFinancialApproval}/${numberOfBatchGifts}) für Fibu `
          );
        }
        if (numberOfUpdatedGiftsForCompletedGift > 0) {
          showNotification(
            `Stapelbearbeitung abgeschlossen (${numberOfUpdatedGiftsForCompletedGift}/${numberOfBatchGifts}) für Entgelt`
          );
        }

        sstTrackingHelper(
          "batch giftupdate",
          "Vorgang wurde gespeichert",
          "/gift/updated"
        );
      }
    } catch (err) {
      console.error(err);
      this.handleApiError(err);
    }
  }

  async deleteGift(id) {
    if (id) {
      try {
        await axios.delete(`${apiBaseURL}/api/gift/${id}`, getAxiosOptions());
        showNotification("Vorgang wurde gelöscht");
        sstTrackingHelper(
          "gift.delete",
          "Vorgang wurde gelöscht",
          "/gift/deleted"
        );
        this.getGifts();
      } catch (err) {
        this.handleApiError(err);
      }
    }
  }

  async uploadInvoiceAttachment(giftId, filedata) {
    try {
      const { data } = await axios.put(
        `${apiBaseURL}/api/gift/${giftId}/attachment`,
        filedata,
        getAxiosOptions()
      );
      sstTrackingHelper(
        "giftPage.invoice.fileUpload",
        "Datei wurde hinzugefügt",
        "/gift/attachment"
      );
      const invoiceAttachments = data.gift.invoice
        ? data.gift.invoice.invoiceAttachments
        : [];
      this.rootStore.giftDataStore.invoiceAttachments = invoiceAttachments;
      return invoiceAttachments;
    } catch (err) {
      console.error(err);
      this.handleApiError("Fehler");
    }
  }

  async deleteInvoiceAttachment(giftId, fileId) {
    try {
      const { data } = await axios.delete(
        `${apiBaseURL}/api/gift/${giftId}/attachment/${fileId}`,
        getAxiosOptions()
      );
      sstTrackingHelper(
        "giftPage.invoice.fileDelete",
        "Datei wurde gelöschen",
        "/gift/attachment"
      );
      const invoiceAttachments = data.gift.invoice
        ? data.gift.invoice.invoiceAttachments
        : [];
      this.rootStore.giftDataStore.invoiceAttachments = invoiceAttachments;
      return invoiceAttachments;
    } catch (err) {
      console.error(err);
      this.handleApiError("Fehler");
    }
  }

  async downloadInvoiceAttachment(giftId, fileId) {
    try {
      const res = await axios.get(
        `${apiBaseURL}/api/gift/${giftId}/attachment/${fileId}`,
        getAxiosOptions()
      );
      sstTrackingHelper(
        "giftPage.invoice.fileDownload",
        "Datei wurde herumtergeladet",
        "/gift/attachment"
      );

      // buffer to base64 string
      const b64Str = this.arrayBufferToBase64(res.data.Body.data);
      // create file from base64 string
      this.b64toFile(
        b64Str,
        res.data.Metadata.fileName,
        res.data.Metadata.contentType
      );
    } catch (err) {
      console.error(err);
      this.handleApiError("Fehler");
    }
  }

  async uploadComplianceAttachment(filedata) {
    try {
      const { data } = await axios.put(
        `${apiBaseURL}/api/settings/attachment`,
        filedata,
        getAxiosOptions()
      );
      sstTrackingHelper(
        "settings.compliance.fileUpload",
        "Datei wurde hinzugefügt",
        "/settings/attachment"
      );
      const complianceAttachments = data.settings.compliance
        ? data.settings.compliance.fileAttachments
        : [];
      this.rootStore.settingsStore.fileAttachments = complianceAttachments;
      return complianceAttachments;
    } catch (err) {
      console.error(err);
      this.handleApiError("Fehler");
    }
  }

  async deleteComplianceAttachment(fileId) {
    try {
      const { data } = await axios.delete(
        `${apiBaseURL}/api/settings/attachment/${fileId}`,
        getAxiosOptions()
      );
      sstTrackingHelper(
        "settings.compliance.fileDelete",
        "Datei wurde gelöschen",
        "/settings/attachment"
      );
      const complianceAttachments = data.settings.compliance
        ? data.settings.compliance.fileAttachments
        : [];
      this.rootStore.settingsStore.fileAttachments = complianceAttachments;
      return complianceAttachments;
    } catch (err) {
      console.error(err);
      this.handleApiError("Fehler");
    }
  }

  async downloadComplianceAttachment(fileId) {
    try {
      const res = await axios.get(
        `${apiBaseURL}/api/settings/attachment/${fileId}`,
        getAxiosOptions()
      );
      sstTrackingHelper(
        "settings.compliance..fileDownload",
        "Datei wurde herumtergeladet",
        "/settings/attachment"
      );

      // buffer to base64 string
      const b64Str = this.arrayBufferToBase64(res.data.Body.data);
      // create file from base64 string
      this.b64toFile(
        b64Str,
        res.data.Metadata.fileName,
        res.data.Metadata.contentType
      );
    } catch (err) {
      console.error(err);
      this.handleApiError("Fehler");
    }
  }
  arrayBufferToBase64(buffer) {
    let binary = "";
    const bytes = [].slice.call(new Uint8Array(buffer));
    bytes.forEach((b) => (binary += String.fromCharCode(b)));
    return window.btoa(binary);
  }

  b64toFile(b64Data, filename, contentType) {
    const sliceSize = 512;
    const byteCharacters = atob(b64Data);
    let byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);
      let byteNumbers = new Array(slice.length);

      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    let file;
    if (detectIE()) {
      file = new Blob(byteArrays, { type: contentType });
      file.name = filename;
    } else {
      file = new File(byteArrays, filename, { type: contentType });
    }
    saveAs(file);
  }

  async getUserSettings() {
    await this.isCleanCSRFId();
    axios
      .get(`${apiBaseURL}/api/user_settings`, getAxiosOptions())
      .then((res) => {
        this.lastApiError = false;
        if (res.data.length > 0 && res.data[0].settings) {
          this.rootStore.userSettingsStore.setUserSettings(
            res.data[0].settings
          );
        } else {
          // if the user settings not exist in DB
          // call setUserSettings with an empty object to set default values and trigger the events
          this.rootStore.userSettingsStore.setUserSettings({});
        }
      })
      .catch((err) => {
        this.handleApiError(err);
      });
  }

  updateUserSettings(settings) {
    axios
      .post(`${apiBaseURL}/api/user_settings`, { settings }, getAxiosOptions())
      .then((res) => {
        this.apiErrorOccured = false;
      })
      .catch((err) => {
        this.handleApiError(err);
      });
  }

  async signUp(data, callback) {
    const { email, firstName, lastName, password, passwordRepeat } = data;
    axios
      .post(
        `${apiBaseURL}/api/auth/signup`,
        { email, firstName, lastName, password, passwordRepeat },
        getAxiosOptions()
      )
      .then((res) => {
        this.apiErrorOccured = false;
        if (callback) {
          callback(res.data);
        }
      })
      .catch((err, res) => {
        this.handleApiError(err);
        if (callback) {
          callback(err.response.data);
        }
      });
  }

  login(data, callback) {
    const { username, password } = data;
    axios
      .post(
        `${apiBaseURL}/api/auth/login`,
        { username, password },
        getAxiosOptions()
      )
      .then((res) => {
        this.apiErrorOccured = false;
        if (callback) {
          callback(res.data);
        }
      })
      .catch((err) => {
        this.handleApiError(err);
        if (callback) {
          callback(err.response.data);
        }
      });
  }

  forgotPassword(data, callback) {
    const { email } = data;
    axios
      .post(
        `${apiBaseURL}/api/auth/forgot-password`,
        { email },
        getAxiosOptions()
      )
      .then((res) => {
        this.apiErrorOccured = false;
        if (callback) {
          callback(res.data);
        }
      })
      .catch((err) => {
        this.handleApiError(err);
        if (callback) {
          callback(err.response.data);
        }
      });
  }

  resetPassword(data, callback) {
    const { email, verificationCode, newPassword, newPasswordRepeat } = data;
    axios
      .post(
        `${apiBaseURL}/api/auth/reset-password`,
        { email, verificationCode, newPassword, newPasswordRepeat },
        getAxiosOptions()
      )
      .then((res) => {
        this.apiErrorOccured = false;
        if (callback) {
          callback(res.data);
        }
      })
      .catch((err) => {
        this.handleApiError(err);
        if (callback) {
          callback(err.response.data);
        }
      });
  }

  async getEmployees() {
    //    this.employees = data;
    const res = await axios.get(`${apiBaseURL}/api/users`, getAxiosOptions());
    try {
      this.apiErrorOccured = false;
      this.employees = res.data;
    } catch (err) {
      this.employees = [];
      this.handleApiError(err);
    }
  }

  async getAdminUsers() {
    const res = await axios.get(
      `${apiBaseURL}/api/users/adminUsers/`,
      getAxiosOptions()
    );
    try {
      this.apiErrorOccured = false;
      this.adminUsers = res.data;
    } catch (err) {
      this.adminUsers = [];
      this.handleApiError(err);
    }
  }
  async updateEmployee(user, overwrite) {
    try {
      const data = { user, overwrite };
      const res = await axios.put(
        `${apiBaseURL}/api/users/${user.id}`,
        data,
        getAxiosOptions()
      );

      if (res.data.message === UPDATE_STATUS.success) {
        showNotification("Mitarbeiter wurde gespeichert");
      }

      this.apiErrorOccured = false;
      const index = this.employees.findIndex((item) => item.id === user.id);
      if (index > -1) {
        this.employees[index] = res.data.employee;
      }
      await this.getUserInfo();

      return res.data;
    } catch (err) {
      this.handleApiError(err);
    }
  }

  async getDataProtection() {
    try {
      this.dataProtection = (
        await axios.get(
          `${this.apiBaseURL}/api/legal_information/data_protection`,
          getAxiosOptions()
        )
      ).data;
    } catch (err) {
      this.handleApiError(err);
    }
  }

  async getImprint() {
    try {
      this.imprint = (
        await axios.get(
          `${this.apiBaseURL}/api/legal_information/imprint`,
          getAxiosOptions()
        )
      ).data;
    } catch (err) {
      this.handleApiError(err);
    }
  }

  async deactivateEmployee(id) {
    try {
      const data = { deactivate: "deactivate" }; // Internet Explorer FIX / IE  cannot handle empty objects / NOT USED ON BACKEND
      const res = await axios.patch(
        `${apiBaseURL}/api/users/${id}/deactivate`,
        data,
        getAxiosOptions()
      );
      this.employees = this.employees.map(function (employee) {
        return employee.id === id ? res.data : employee;
      });

      showNotification("Mitarbeiter wurde deactiviret");
    } catch (err) {
      this.employees = [];
      this.handleApiError(err);
    }
  }

  handleApiError(err) {
    this.lastApiError = err;
    console.log(err);

    if (err.response) {
      if (err.response.status === 403) {
        // Session is expired or user logged out in other session
        if (window.location.host.startsWith("localhost")) {
          this.lastApiErrorMessage =
            "API access failed - are you logged in on the dev instance?";
          this.rootStore.modalStore.showChoice(
            "API-Error 403",
            this.lastApiErrorMessage,
            "Login",
            () =>
              window.open(
                "https://gm.smartapplications-dev.haufe.de/",
                "_blank"
              ),
            "Restart",
            () => (window.location = "/")
          );
        } else {
          if (!this.userInfo.advForce) {
            this.lastApiErrorMessage =
              "Ihre Sitzung ist ungültig, bitte loggen Sie sich erneut ein.";
            setTimeout(() => {
              sstTrackingHelper(
                "timeout",
                "Session ungültig",
                "/app/session/timeout"
              );
              window.location = "/auth/login";
            }, 100);
          }
        }
      } else if (err.response.status === 404) {
        // Session is expired or user logged out in other session
        if (window.location.host.startsWith("localhost")) {
          this.lastApiErrorMessage = "Not found error";
          this.rootStore.modalStore.showConfirm(
            "API-Error 404",
            this.lastApiErrorMessage
          );
        }
      } else if (err.response.status === 405) {
        if (window.location.host.startsWith("localhost")) {
          this.lastApiErrorMessage = "Not allowed";
          this.rootStore.modalStore.showConfirm(
            "API-Error 405",
            this.lastApiErrorMessage
          );
        }
      } else if (err.response.status === 422) {
        // Session is expired or user logged out in other session
        if (window.location.host.startsWith("localhost")) {
          this.lastApiErrorMessage = "API error: " + err.response.message;
          this.rootStore.modalStore.showConfirm(
            "API-Error 422",
            "API error:\n" + JSON.stringify(err.response.data.errors)
          );
        } else {
          this.lastApiErrorMessage =
            "Beim Verarbeiten der Anfrage ist ein Fehler aufgetreten (422).";
        }
      } else if (err.response.status === 500) {
        // Session is expired or user logged out in other session
        if (window.location.host.startsWith("localhost")) {
          this.lastApiErrorMessage = "Internal server error";
          this.rootStore.modalStore.showConfirm(
            "API-Error 500",
            this.lastApiErrorMessage
          );
        } else {
          this.lastApiErrorMessage =
            "Beim Verarbeiten der Anfrage ist ein Fehler aufgetreten (500).";
        }
      }
    }
  }
  // the userinfo route will send the csrf token if req.session.sma.csrfId is undefined
  async isCleanCSRFId() {
    try {
      await axios.put(`${apiBaseURL}/api/clean_csrf`, getAxiosOptions());
      return true;
    } catch (e) {
      return false;
    }
  }
}

decorate(APIStore, {
  // Observables
  pingErrorOccured: observable,
  apiErrorOccured: observable,
  apiErrorDismissed: observable,
  lastApiError: observable,
  lastApiErrorMessage: observable,

  userInfo: observable,
  isGroupDataShared: observable,
  settings: observable,
  gifts: observable,
  roles: observable,
  employees: observable,
  advAccepted: observable,
  advAcceptedDate: observable,
  advDocument: observable,
  dataProtection: observable,
  imprint: observable,
  adminUsers: observable,

  // Actions
  pingBackend: action,
  getUserInfo: action,
  sendFeedback: action,
  updateEmployee: action,

  acceptADV: action,
  downloadRTF: action,
  deactivateEmployee: action,
});
