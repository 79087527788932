import React, { useMemo, useState, useEffect, useRef } from "react";
import { inject, observer } from "mobx-react";
import moment from "moment";
import "./_style.scss";
import {
  editIcon,
  downloadIcon,
  trashIcon,
  checkIcon,
} from "../../../Assets/dynamic-svg/general-icons";
import { royalBlue, blackPearl } from "../../../Utils/styleHelper";
import { formatNumber } from "../../../sma-shared/Utils/utils";
import {
  containsText,
  sstTrackingHelper,
  generateGtmEventName,
} from "../../../Utils/utils";
import {
  fillEmployerSettingsWarning,
  FOOTER_FIX_HEIGHT,
  giftStatusOverview,
  HEADER_FIX_HEIGHT,
  ROLES,
  status,
  STICKY_FIX_HEIGHT,
  reasonOptions,
  receiverType,
  numberOfReceiversType,
  GIFT_VALUE_TYPE,
  activeArea,
  actions,
} from "../../../Utils/constants";
import Table from "../../../Components/Table";
import ContextMenuCell from "../../../Components/Table/Cells/ContextMenuCell";
import FiltersBar from "../../../Components/FiltersBar";
import EmptyTable from "../../../Components/EmptyTable";
import NotificationArea from "../../../Components/NotificationArea";
import { exportToXLSX } from "../../../Utils/excel";
import { WORKFLOW } from "../../CreateGiftPages/WorkflowNavigation/workflow";
import { getLatestGiftStatus } from "../helper";
import { isEmpty, roundToDecimals } from "../../../sma-shared/Utils/utils";
import { downloadReport } from "../../../Utils/pdf";
import CheckboxCell from "../../../Components/Table/Cells/CheckboxCell";
import { getArray } from "../../../Utils/gift";

const OVERVIEW_TABLE_OFFSET = 310;

function RightContent(props) {
  const { apiStore, filtersStore, giftDataStore, modalStore } = props.rootStore;

  const [activeFilters, setActiveFilters] = useState(
    filtersStore.activeFilters
  );
  const [searchFilterValue, setSearchFilterValue] = useState(
    filtersStore.searchFilterValue.toLowerCase()
  );
  const [gifts, setGifts] = useState([]);
  const [data, setData] = useState([]);
  const [isBatchProcessingActive, setIsBatchProcessingActive] = useState(false);
  const [overviewBodyStyle, setOverviewBodyStyle] = useState();

  const dataRef = useRef();
  dataRef.current = data;

  const trackRowClick = () => {
    sstTrackingHelper("Fallübersicht", "Sorting the table", "/sorting");
  };

  const setGtmIdForHeaderClick = () => {
    return generateGtmEventName(
      activeArea.caseOverview,
      actions.sort,
      "TableHeader"
    );
  };

  useEffect(() => {
    const mappedData = mapData(apiStore.gifts);
    setGifts(mappedData);
    setTableSize();

    window.addEventListener("resize", setTableSize);
    return () => {
      window.removeEventListener("resize", setTableSize);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const mappedData = mapData(apiStore.gifts);
    setTableSize();
    setGifts(mappedData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiStore.gifts]);

  useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeFilters, searchFilterValue, gifts]);

  useEffect(() => {
    setActiveFilters(filtersStore.activeFilters.map((filter) => filter.value));
    setSearchFilterValue(filtersStore.searchFilterValue.toLowerCase());
    setTableSize();
  }, [filtersStore.activeFilters, filtersStore.searchFilterValue]);

  const setTableSize = () => {
    const adjustment = 100;
    const headerElement = document.getElementsByClassName("header")[0];
    const headerHeight = headerElement
      ? headerElement.clientHeight
      : HEADER_FIX_HEIGHT;
    const footerElement = document.getElementsByClassName("footer")[0];
    const footerHeight = footerElement
      ? footerElement.clientHeight
      : FOOTER_FIX_HEIGHT;
    const stickyElement = document.getElementsByClassName(
      "sticky-left-container"
    )[0];
    const stickyHeigth = stickyElement
      ? stickyElement.clientHeight
      : STICKY_FIX_HEIGHT;

    const hasVScroll = window.document.body.scrollHeight > window.innerHeight;

    const difference =
      window.innerHeight -
      stickyHeigth -
      headerHeight -
      footerHeight -
      adjustment;
    if (hasVScroll && difference < 0) {
      const scrollOffset = Math.max(
        0,
        OVERVIEW_TABLE_OFFSET -
          window.document.body.scrollHeight +
          window.innerHeight
      );
      setOverviewBodyStyle({ height: `calc(100vh - ${scrollOffset}px)` });
    } else {
      setOverviewBodyStyle({
        height: `calc(100vh - ${OVERVIEW_TABLE_OFFSET}px)`,
      });
    }
  };

  const getCheckBoxName = (giftStatus) => {
    // last status before fibu can do a batch processing
    if (
      giftStatus === status.financialAccountingProcessing &&
      (apiStore.roles.includes(ROLES.admin) ||
        apiStore.roles.includes(ROLES.accounting))
    ) {
      return "Fibu";
    }
    // last status before entgelt can do a batch processing
    if (
      giftStatus === status.payrollProcessing &&
      (apiStore.roles.includes(ROLES.admin) ||
        apiStore.roles.includes(ROLES.payroll))
    ) {
      return "Entgelt";
    }

    return "";
  };

  const mapData = (data) => {
    const mappedData = data.map((gift) => {
      const receivers =
        gift.receiver && gift.receiver.receivers
          ? getArray(gift.receiver.receivers)
          : [];
      const receiver =
        receivers.length === 0
          ? ""
          : receivers.length === 1
          ? `${receivers[0].lastName}, ${receivers[0].firstName}`
          : `Mehrere (${receivers.length})`;

      const receiverCompany =
        gift.entryCheck.receiverType === receiverType.employee.value
          ? apiStore.settings.company.name
          : receivers.length === 0
          ? ""
          : receivers.length === 1
          ? receivers[0].company
          : `Mehrere (${receivers.length})`;

      const lastestStatus = getLatestGiftStatus(gift);

      const cost =
        (gift.invoice ? gift.invoice.grossValue : "") ||
        (gift.gift ? gift.gift.giftValue : "");
      const giftValueType =
        gift.invoice && gift.invoice.grossValue
          ? GIFT_VALUE_TYPE.gross.value
          : gift.gift
          ? gift.gift.giftValueType
          : "";
      const senderEmail = gift.sender ? gift.sender.email : "";

      return {
        id: gift._id,
        allowToDelete:
          apiStore.roles.includes(ROLES.admin) ||
          (apiStore.userInfo.Email === senderEmail &&
            lastestStatus === status.draft),
        gift: gift.gift ? gift.gift.giftDescription : "",
        value: cost ? roundToDecimals(cost, 2) : "",
        giftValueType,
        date: gift.gift && gift.gift.giftDate ? moment(gift.gift.giftDate) : "",
        receiver,
        company: receiverCompany,
        lastStatus: giftStatusOverview[lastestStatus],
        statusClass: lastestStatus,
        batchProcessing: getCheckBoxName(lastestStatus),
        selectedCaseForBatchProcessing: false,
        searchableFields: createSearchableFields(gift),
        senderEmail: gift.sender.email,
      };
    });

    return mappedData;
  };

  const createSearchableFields = (gift) => {
    const searchableFields = {};
    if (!gift) {
      return searchableFields;
    }
    const lastestStatus = getLatestGiftStatus(gift);
    searchableFields.status = giftStatusOverview[lastestStatus];

    if (
      gift.entryCheck &&
      gift.entryCheck.receiverType &&
      receiverType[gift.entryCheck.receiverType]
    ) {
      searchableFields.receiverType =
        receiverType[gift.entryCheck.receiverType].label;
    }
    if (gift.sender) {
      searchableFields.senderFirstName = gift.sender.firstName;
      searchableFields.senderLastName = gift.sender.lastName;
      searchableFields.senderCompany = gift.sender.company;
      searchableFields.senderEmail = gift.sender.email;
    }
    if (gift.receiver) {
      searchableFields.receivers =
        gift.receiver && gift.receiver.receivers ? gift.receiver.receivers : [];
      searchableFields.numberOfReceivers = numberOfReceiversType[
        gift.receiver.numberOfReceivers
      ]
        ? numberOfReceiversType[gift.receiver.numberOfReceivers].label
        : "";
    }
    if (gift.gift) {
      searchableFields.giftReason = reasonOptions[gift.gift.giftReason]
        ? reasonOptions[gift.gift.giftReason].label
        : "";
      searchableFields.giftDescription = gift.gift.giftDescription;
      searchableFields.giftDescriptionOccasion =
        gift.gift.giftDescriptionOccasion;
      searchableFields.giftDate = gift.gift.giftDate
        ? moment(gift.gift.giftDate).format("DD.MM.YYYY")
        : "";
      searchableFields.giftValueType =
        gift.invoice && gift.invoice.grossValue
          ? GIFT_VALUE_TYPE.gross.label
          : gift.gift.giftValue && GIFT_VALUE_TYPE[gift.gift.giftValueType]
          ? GIFT_VALUE_TYPE[gift.gift.giftValueType].label
          : "";
      searchableFields.giftValue = gift.gift.giftValue;
    }
    if (gift.invoice) {
      searchableFields.invoiceGrossValue = gift.invoice.grossValue;
      searchableFields.invoiceInternalNumber = gift.invoice.internalNumber;
      searchableFields.invoiceNumber = gift.invoice.invoiceNumber;
      searchableFields.invoiceNetValue = gift.invoice.netValue;
    }
    searchableFields.guidance = gift.guidance;

    return searchableFields;
  };

  const getFilterdGiftsData = () => {
    const idList = data.map((gift) => gift.id);

    const giftsToExport = apiStore.gifts.filter((gift) =>
      idList.includes(gift._id)
    );
    const rows = [];
    for (const gift of giftsToExport) {
      const receivers = gift.receiver ? gift.receiver.receivers : [];
      if (!receivers || receivers.length === 0) {
        const row = { ...gift };
        row.lastStatus = giftStatusOverview[getLatestGiftStatus(gift)];
        row.receiverCompany =
          gift.entryCheck.receiverType === receiverType.employee.value
            ? apiStore.settings.company.name
            : "";
        rows.push(row);
      } else {
        for (const receiver of receivers) {
          const row = { ...gift };
          row.receiverName = receiver.firstName;
          row.receiverLastName = receiver.lastName;
          row.receiverEmail = receiver.email;
          row.receiverPersonalId = receiver.personalId;
          row.receiverCompany =
            gift.entryCheck.receiverType === receiverType.employee.value
              ? apiStore.settings.company.name
              : receiver.company;
          row.lastStatus = giftStatusOverview[getLatestGiftStatus(gift)];
          rows.push(row);
        }
      }
    }
    return rows;
  };

  const loadData = () => {
    let filteredData =
      activeFilters.length > 0
        ? gifts.filter(
            (gift) => gift.lastStatus === giftStatusOverview[activeFilters[0]]
          )
        : gifts;

    if (searchFilterValue) {
      filteredData = filteredData.filter((gift) =>
        containsText(gift.searchableFields, searchFilterValue)
      );
    }

    setData(filteredData);
  };

  const menuActions = (allowToDelete) => {
    const items = [
      {
        label: "Bearbeiten",
        icon: editIcon(blackPearl),
        click: (index) => {
          handleEditGift(index);
        },
      },
      {
        label: "Herunterladen",
        icon: downloadIcon(blackPearl),
        click: (index) => {
          handleDownloadReport(index);
          sstTrackingHelper(
            "Fallübersicht",
            "Download the overview in Excel Format",
            "/downliad-overview"
          );
        },
      },
    ];

    if (allowToDelete) {
      items.push({
        label: "Löschen",
        icon: trashIcon(blackPearl),
        click: (index) => {
          handleDeleteGift(index);
        },
      });
    }

    return items;
  };

  const handleEditGift = (index) => {
    const gift = apiStore.gifts.find(
      (gift) => gift._id === dataRef.current[index].id
    );
    if (gift) {
      const workflowPath = getWorkflowStep(gift);
      props.history.push(workflowPath);
    }
  };

  const getWorkflowStep = (gift) => {
    const giftId = gift._id;
    giftDataStore.editGift(gift);
    let firstInvalidWorkflowIndex = WORKFLOW.findIndex(
      (w) => !w.isValid(giftDataStore)
    );
    while (firstInvalidWorkflowIndex > 0) {
      if (WORKFLOW[firstInvalidWorkflowIndex].isVisible(props.rootStore)) {
        break;
      } else {
        firstInvalidWorkflowIndex--;
      }
    }

    const workflowPath =
      firstInvalidWorkflowIndex >= 0
        ? WORKFLOW[firstInvalidWorkflowIndex].path(giftId)
        : WORKFLOW[WORKFLOW.length - 1].path(giftId);
    return workflowPath;
  };

  const handleDownloadReport = (index) => {
    const gift = apiStore.gifts.find(
      (gift) => gift._id === dataRef.current[index].id
    );
    downloadReport(gift);
  };

  const handleDeleteGift = (index) => {
    modalStore.showChoice(
      "Fall löschen",
      "Sind Sie sicher?",
      "Abbrechen",
      () => {},
      "Löschen",
      () => {
        apiStore.deleteGift(dataRef.current[index].id);
      }
    );
  };

  const batchProcessGiftCases = async () => {
    setIsBatchProcessingActive(true);
    const financialApprovalGiftList = dataRef.current.filter(
      (gift) =>
        gift.selectedCaseForBatchProcessing &&
        gift.batchProcessingAction === "Fibu"
    );
    const giftCompeleteGiftList = dataRef.current.filter(
      (gift) =>
        gift.selectedCaseForBatchProcessing &&
        gift.batchProcessingAction === "Entgelt"
    );

    await apiStore.updateBatchGifts(
      financialApprovalGiftList,
      giftCompeleteGiftList
    );
    setIsBatchProcessingActive(false);
  };

  const onBatchSelectionChange = (rowIndex, value) => {
    const data = [...dataRef.current];
    data[rowIndex].selectedCaseForBatchProcessing =
      !data[rowIndex].selectedCaseForBatchProcessing;
    data[rowIndex].batchProcessingAction = value;
    setData(data);
    setIsBatchProcessingActive(
      data.some((value) => value.selectedCaseForBatchProcessing)
    );
  };

  const getGiftValue = (gift) => {
    const { value, giftValueType } = gift.row.original;
    const giftTypeLabel = GIFT_VALUE_TYPE[giftValueType]
      ? GIFT_VALUE_TYPE[giftValueType].label.toLowerCase()
      : "";
    return value === "" ? "" : `${formatNumber(value)} (${giftTypeLabel})`;
  };

  const columns = useMemo(
    () => {
      const tableColumns = [
        {
          Header: (props) => (
            <span onClick={trackRowClick} data-gtmid={setGtmIdForHeaderClick()}>
              Empfänger
            </span>
          ),
          accessor: "receiver",
          Cell: (props) => (
            <span className={props.row.original.statusClass}>
              {props.value}
            </span>
          ),
        },
        {
          Header: (props) => (
            <span onClick={trackRowClick} data-gtmid={setGtmIdForHeaderClick()}>
              Unternehmen
            </span>
          ),
          accessor: "company",
          Cell: (props) => (
            <span className={props.row.original.statusClass}>
              {props.value}
            </span>
          ),
        },
        {
          Header: (props) => (
            <span onClick={trackRowClick} data-gtmid={setGtmIdForHeaderClick()}>
              Geschenk
            </span>
          ),
          accessor: "gift",
          Cell: (props) => (
            <span className={props.row.original.statusClass}>
              {props.value}
            </span>
          ),
        },
        {
          Header: (props) => (
            <span onClick={trackRowClick} data-gtmid={setGtmIdForHeaderClick()}>
              Wert pro Geschenk
            </span>
          ),
          accessor: "value",
          Cell: (props) => (
            <span className={props.row.original.statusClass}>
              {getGiftValue(props)}
            </span>
          ),
        },
        {
          Header: (props) => (
            <span onClick={trackRowClick} data-gtmid={setGtmIdForHeaderClick()}>
              Datum der Schenkung
            </span>
          ),
          accessor: "date",
          Cell: (props) => (
            <span className={props.row.original.statusClass}>
              {props.value ? props.value.format("DD.MM.YYYY") : ""}
            </span>
          ),
        },

        {
          Header: (props) => (
            <span onClick={trackRowClick} data-gtmid={setGtmIdForHeaderClick()}>
              Status
            </span>
          ),
          accessor: "lastStatus",
          Cell: (props) => (
            <span className={props.row.original.statusClass}>
              {isEmpty(props.value) ? "" : props.value}
            </span>
          ),
        },
      ];
      if (
        apiStore.roles.includes(ROLES.admin) ||
        apiStore.roles.includes(ROLES.accounting) ||
        apiStore.roles.includes(ROLES.payroll)
      ) {
        tableColumns.push({
          Header: (props) => (
            <span onClick={trackRowClick} data-gtmid={setGtmIdForHeaderClick()}>
              Bearbeitung
            </span>
          ),
          accessor: "batchProcessing",
          Cell: (props) =>
            isEmpty(props.value) ? (
              ""
            ) : (
              <CheckboxCell
                row={props.row}
                value={props.value}
                checked={props.row.original.selectedCaseForBatchProcessing}
                column={{
                  onChange: () =>
                    onBatchSelectionChange(props.row.index, props.value),
                }}
              />
            ),
        });
      }
      tableColumns.push({
        Header: (props) => <span onClick={trackRowClick}></span>,
        accessor: "menu",
        Cell: (props) => (
          <ContextMenuCell
            row={props.row}
            column={{
              listItems: menuActions(props.row.original.allowToDelete),
            }}
          />
        ),
      });
      return tableColumns;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [apiStore.roles]
  );

  const renderNotification = () => {
    return apiStore.settings === "" ? (
      <NotificationArea message={fillEmployerSettingsWarning} />
    ) : (
      ""
    );
  };

  const renderHeader = () => {
    return (
      apiStore.settings && (
        <div className="overview-page-header">
          <span className="title">Fälle</span>
          {/* USED ONLY FOR TESTING */}
          {/* <button className={`text-button `} onClick={() => duplicateACase()}>
            Duplicate selected case
          </button> */}
          <FiltersBar />
          <div className="overview-button-bar">
            {(apiStore.roles.includes(ROLES.admin) ||
              apiStore.roles.includes(ROLES.accounting) ||
              apiStore.roles.includes(ROLES.payroll)) && (
              <button
                className={`text-button ${
                  !isBatchProcessingActive ? "inactive" : ""
                }`}
                onClick={() => batchProcessGiftCases()}
              >
                <span className="icon-general">{checkIcon(royalBlue)}</span>
                Bearbeitung abschließen
              </button>
            )}
            <button
              className="text-button"
              onClick={(e) => {
                exportToXLSX(getFilterdGiftsData());
              }}
              data-gtmid={generateGtmEventName(
                activeArea.caseOverview,
                actions.download,
                "CaseOverview"
              )}
            >
              <span className="icon-general">{downloadIcon(royalBlue)}</span>
              Fallübersicht herunterladen
            </button>
          </div>
        </div>
      )
    );
  };

  const renderData = () => {
    return (
      <section>
        {apiStore.settings && (
          <Table
            columns={columns}
            data={data}
            className="gifts-overview"
            bodyStyle={overviewBodyStyle}
            getRowProps={(row) => ({
              onClick: () => handleEditGift(row.index),
              style: {
                cursor: "pointer",
              },
            })}
          />
        )}
        {apiStore.settings === "" && (
          <EmptyTable message={"Noch keine Fälle angelegt."} />
        )}
      </section>
    );
  };

  return (
    <div className="container-right overview-content">
      {renderNotification()}
      {apiStore.roles.includes(ROLES.admin) ||
      apiStore.roles.includes(ROLES.accounting) ||
      apiStore.roles.includes(ROLES.payroll)
        ? renderHeader()
        : ""}
      {renderData()}
    </div>
  );
}

export default inject("rootStore")(observer(RightContent));
