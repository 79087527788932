import React, { useState } from "react";
import { magnifierIcon } from "../../Assets/dynamic-svg/general-icons";
import "./_style.scss";
import { royalBlue, blackPearl } from "../../Utils/styleHelper";
import { sstTrackingHelper, generateGtmEventName } from "../../Utils/utils";
import { actions, activeArea } from "../../Utils/constants";

function SearchFilter(props) {
  const [isInputFocused, setIsInputFocused] = useState(false);
  const [searchText, setSearchText] = useState("");

  const renderSearchIcon = () => {
    return (
      <span
        className="icon-general"
        onClick={() => {
          props.searchFor(searchText);
        }}
      >
        {isInputFocused ? magnifierIcon(royalBlue) : magnifierIcon(blackPearl)}
      </span>
    );
  };

  const handleSearchInputFocus = () => {
    setIsInputFocused(true);
  };

  const handleSearchInputBlur = () => {
    setIsInputFocused(false);
  };

  const handleSearchFilterChange = (e) => {
    setSearchText(e.target.value);
  };

  const renderSearchInput = () => {
    return (
      <input
        className="search-input"
        type="text"
        value={searchText}
        onChange={handleSearchFilterChange}
        placeholder={props.searchPlaceholder}
        onFocus={handleSearchInputFocus}
        onBlur={handleSearchInputBlur}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            props.searchFor(e.target.value);
            sstTrackingHelper(
              "Fallübersicht",
              "Searching for a case",
              "/search"
            );
          } else if (e.key === "Escape") {
            setSearchText("");
            props.searchFor("");
          }
        }}
      />
    );
  };

  return (
    <div className="search-filter">
      <div id="filterTag">{props.searchTitle}</div>
      <div className="filter-options-container">
        <div
          className="search-input-container"
          data-gtmid={generateGtmEventName(
            activeArea.caseOverview,
            actions.insert,
            "SearchTerm"
          )}
        >
          {renderSearchIcon()}
          {renderSearchInput()}
        </div>
      </div>
    </div>
  );
}

export default SearchFilter;
