import { observable, decorate, action } from "mobx";
import { sstTrackingHelper } from "../Utils/utils";

/*
  Filters Store - used for filtering the Overview table
*/

export const ALL_FILTER_LABEL = "Alle";
export const ALL_FILTER_VALUE = "alle";

export default class FiltersStore {
  rootStore;

  searchFilterValue = "";
  showAllTableEntries = true;
  filterOptions = observable([]);
  activeFilters = observable([]);

  constructor(rootStore) {
    this.rootStore = rootStore;
  }

  setFilterOptions(filterOptions = [], hasAllOptions = true) {
    this.searchFilterValue = "";
    this.activeFilters = [];
    this.filterOptions = [];
    let startIndex = 0;
    if (hasAllOptions) {
      this.filterOptions.push({
        label: ALL_FILTER_LABEL,
        value: ALL_FILTER_VALUE,
        index: startIndex++,
        onClick: () => {
          this.resetActiveFilters();
        },
      });
    }

    for (const option of filterOptions) {
      this.filterOptions.push({
        label: option.label,
        value: option.value,
        index: startIndex++,
        onClick: () => {
          this.addFilter(option);
        },
      });
    }
  }

  addFilter(filter) {
    /* add filter */
    this.activeFilters = [];
    this.activeFilters = [...this.activeFilters, filter];
    this.rootStore.userSettingsStore.saveSettings();
    sstTrackingHelper("Fallübersicht", "Status filter changed", "/filter");
  }

  isFilterActive(filter) {
    return this.activeFilters
      .map((activeFilter) => activeFilter.label)
      .includes(filter.label);
  }

  getFilterObject(filter) {
    return this.filterOptions.filter((option) => {
      return option.label === filter;
    })[0];
  }

  resetActiveFilters() {
    this.activeFilters = [];
    this.rootStore.userSettingsStore.saveSettings();
  }
}

decorate(FiltersStore, {
  // Observables
  searchFilterValue: observable,
  showAllTableEntries: observable,
  activeFilters: observable,

  // Actions
  setFilterOptions: action,
});
